    /*
    Flaticon icon font: Flaticon
    Creation date: 19/04/2018 15:30
    */

    @font-face {
  font-family: "iconfont";
  src: url("../font/iconfont.eot");
  src: url("../font/iconfont.eot?#iefix") format("embedded-opentype"),
       url("../font/iconfont.woff") format("woff"),
       url("../font/iconfont.ttf") format("truetype"),
       url("../font/iconfont.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "iconfont";
    src: url("../font/iconfont.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .pdf:before { content: "\f100"; }
    .vr:before { content: "\f101"; }
    .play:before { content: "\f102"; }
    .click:before { content: "\f103"; }
    .book:before { content: "\f104"; }
    .info:before { content: "\e900"; }
    .speaker:before { content: "\e901"; }
    
    $pdf: "\f100";
    $vr: "\f101";
    $play: "\f102";
    $click: "\f103";
    $book: "\f104";
    $info: "\e900";
    $speaker: "\e901";