@charset 'utf-8';
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.min.css);

// Fonts
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../font/open-sans-v17-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../font/open-sans-v17-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../font/open-sans-v17-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../font/open-sans-v17-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('../font/open-sans-v17-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../font/open-sans-v17-latin-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../font/open-sans-v17-latin-300italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
    url('../font/open-sans-v17-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../font/open-sans-v17-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../font/open-sans-v17-latin-300italic.woff') format('woff'),
    /* Modern Browsers */
    url('../font/open-sans-v17-latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../font/open-sans-v17-latin-300italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../font/open-sans-v17-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../font/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../font/open-sans-v17-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../font/open-sans-v17-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../font/open-sans-v17-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../font/open-sans-v17-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../font/open-sans-v17-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('../font/open-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../font/open-sans-v17-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../font/open-sans-v17-latin-italic.woff') format('woff'),
    /* Modern Browsers */
    url('../font/open-sans-v17-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../font/open-sans-v17-latin-italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../font/open-sans-v17-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../font/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../font/open-sans-v17-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../font/open-sans-v17-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('../font/open-sans-v17-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../font/open-sans-v17-latin-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'slick.scss';
@import 'slick-theme.scss';
@import 'additional.scss';
@import 'components/flaticon';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
// @include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@import 'ticker';

body {
  &.no-scroll {
    overflow: hidden;
  }
}

figure {
  margin: 0;
}

h1 {
  font-size: 40px;

  @include breakpoint(large down) {
    font-size: 32px;
  }

  @include breakpoint(medium down) {
    font-size: 24px;
  }
}

h2 {
  font-size: 30px;

  @include breakpoint(large down) {
    font-size: 26px;
  }

  @include breakpoint(medium down) {
    font-size: 20px;
  }
}

h3 {
  font-size: 20px;

  @include breakpoint(large down) {
    font-size: 20px;
  }

  @include breakpoint(medium down) {
    font-size: 18px;
  }
}

h4 {
  font-size: 16px;
}

.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  stroke-width: 0;
  stroke: #fff;
  fill: #fff;
  position: relative;
  top: 4px;
  margin-right: 4px;
}

.title-bar {
  margin: 0;
  padding: 5px;
  box-sizing: border-box;
  display: none;
  background: $white;

  .row {
    .columns {
      height: 57px;
      display: flex;
      align-items: center;

      &:last-of-type {
        justify-content: flex-end;
        transform: translateY(-4px);
      }
    }
  }

  .menu-icon {
    top: 4px;
    margin: 0;
  }

  .icon-nav-wrapper {
    padding: 0;
    display: flex;
    justify-content: space-around;
    margin: 6px 0 0;

    li {
      list-style: none;

      @media screen and (max-width: 638px) {
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      a {
        i {
          color: white;
          display: block !important;
          font-size: 25px;

          &:hover {
            color: $secondary-color;
          }
        }
      }
    }
  }

  .metaSearch {
    display: none;
  }
}

.logo-mobile {
  position: relative;
  height: 57px;
  width: auto;
  top: 2px;
}

.ref {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.content-wrap {
  margin-top: 117px;

  @media screen and (max-width: 1024px) {
    margin-top: 57px;
  }

  &.event,
  &.news,
  &.searchresult-wrapper {
    margin-top: 216px;

    @media screen and (max-width: 1024px) {
      margin-top: 73px;
    }
  }

  .row {
    margin-bottom: 90px;

    @include breakpoint(medium down) {
      margin-bottom: 50px;
    }
  }

  .row .row {
    margin-bottom: 0;
  }

  p a,
  a.arrows {
    &:after {
      content: '\f101';
      padding-left: 5px;
      font-family: 'Fontawesome';
      font-weight: normal;
      font-size: 150%;
      line-height: 0;
      position: relative;
      bottom: -3px;
    }

    &:hover {
      color: $secondary-color;
    }
  }
}

.startpage {
  .content-wrap {
    margin-top: 146px;

    @media screen and (max-width: 1024px) {
      margin-top: 57px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;

  &.blue {
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  &.green {
    color: $dark-gray;
  }
}

a {
  font-weight: $global-weight-bold;

  &.pdf {
    &:before {
      content: '\f1c1';
      font-family: 'Fontawesome';
      padding-right: 5px;
      font-weight: normal;
    }
  }
}

i {
  color: $dark-gray;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
  border: none;
  box-shadow: inset 0 3px 0 0px $light-blue;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}

[type='text']:focus,
[type='password']:focus,
[type='date']:focus,
[type='datetime']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='week']:focus,
[type='email']:focus,
[type='number']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='url']:focus,
[type='color']:focus,
textarea:focus {
  border: none;
  outline: none;
  box-shadow: inset 0 3px 0 0px $secondary-color;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

.button {
  font-weight: $global-weight-normal;
  text-transform: uppercase;
  position: relative;

  &.-center {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.button.search {
  font-family: 'Fontawesome';
  color: $white;
  background: transparent;
  font-size: 20px;
  padding: 0.5em;
  margin: 0;
}

.input-group.search input {
  box-shadow: unset;
  height: 42px;
  background: transparent;
}

/* .content-wrap .button::after {
  content: '';
  position: absolute;
  top: -1px;
  right: -66px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 65px 0 0;
  border-color: $primary-color transparent transparent transparent;
  transition: border-color 0.25s ease-out;
}

.content-wrap .button:hover::after {
  border-color: scale-color($primary-color, $lightness: -14%) transparent transparent transparent;
} */

.slick-next:before {
  content: '\f105';
}

.slick-prev:before {
  content: '\f104';
}

.slick-prev:before,
.slick-next:before {
  font-size: 60px;
  opacity: 0.9;
  color: $black;
}

.gray {
  background: $light-gray;
  margin-bottom: 80px;
}

.gray-small {
  background: $light-gray;
  margin-bottom: 0;
}

.gray>.row>.columns {
  padding-top: 50px;
  padding-bottom: 50px;
}

.gray>.row>.columns {
  border-bottom: 5px solid $white;
  border-left: none;

  @include breakpoint(medium) {
    border-bottom: none;

    &:last-child {
      border-left: 5px solid $white;
      border-right: 5px solid $white;
    }
  }
}

.news {
  border-bottom: 5px solid $white;
  padding-bottom: 10px;
  margin-top: 10px;

  img {
    margin-bottom: 20px;

    @include breakpoint(smallium) {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      margin-bottom: 20px;
    }

    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  h4 {
    margin-bottom: 0.2rem;
  }

  p {
    margin-bottom: 0;
  }
}

.layer {
  background: rgba($light-gray, 0);
  transition: background 0.3s ease-in-out;
  position: absolute;
  width: 100%;
  height: 0;
  top: 117px;
  left: 0;
  z-index: -1;
}

header.smaller .layer {
  top: 67px;
}

#overlay-menu:hover+.layer {
  @include breakpoint(large) {
    background: rgba($light-gray, 0.6);
    transition: background 0.3s ease-out;
    height: 100vh;
  }
}

//HEADER
#header-main {
  height: auto;
  position: fixed;
  width: 100%;
  background: $white;
  color: $white;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 6000;
  transition: height 0.3s ease-in-out, background 0.8s ease-in-out;
  box-sizing: border-box;
  height: 67px;

  &.open {
    height: 100%;
    background: 0 none;
  }

  @include breakpoint(large) {
    padding: 30px 0;
    height: 117px;
    background: $primary-color;
  }

  @include breakpoint(medium down) {
    height: 67px;
    padding: 0;
    overflow: auto;
    z-index: 9999;
  }

  &.smaller {
    @include breakpoint(large) {
      padding: 0;
      height: 67px;
      background: $primary-color;
      color: $white;
    }
  }

  &.dropdown-pane {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &.smaller .dropdown-pane {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .top-bar {
    background: $white;
    transition: background 0.3s;
  }
}

#header-main.smaller .top-bar {
  background: $primary-color;
  padding: 0;
  height: 0;
}

#header-main.smaller a {
  color: $white;
}

#header-main .logo-smaller {
  display: none;
}

#header-main.smaller .logo-smaller {
  display: block;
  float: left;
  height: 54px;
  width: auto;

  @include horizontal-center;
  margin: 5px;
}

#header-main .fa {
  display: none;
}

#header-main.smaller .fa {
  display: block;
  float: left;
  font-size: 30px;
  padding: 10px 0 5px 34px;
  height: 50px;
  border-left: 5px solid $white;

  &.fa-search {
    &:before {
      position: relative;
      top: -4px;
    }
  }
}

#header-main .logo {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  width: auto;
  height: 57px;
}

#header-main.smaller .logo {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

#header-main .top-bar-top {
  padding-right: 25px;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 1023px) {
    background: $white;
    color: $primary-color;

    i {
      color: $primary-color;
    }

    a {
      color: $primary-color;
    }
  }

  .row {
    >.column {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media screen and (max-width: 1023px) {
        flex-direction: column-reverse;
      }

      ul {
        margin: 0 24px 0 0;
        padding: 0;
        display: flex;

        @media screen and (max-width: 1023px) {
          margin: 0;
          flex-direction: column;
          justify-content: flex-start;
          width: 100%;
          border-bottom: 1px solid $white;
        }

        li {
          list-style: none;

          @media screen and (max-width: 1023px) {
            position: relative;
          }

          &+li {
            margin-left: 24px;

            @media screen and (max-width: 1023px) {
              margin-left: 0;
            }
          }

          a {
            color: $white;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;

            &:hover {
              text-decoration: underline;
            }

            @media screen and (max-width: 1023px) {
              font-size: 20px;
              line-height: 28px;
              padding: 15px 10px 15px 0;
              display: block;
              width: max-content;
              color: $primary-color;

              &:hover {
                text-decoration: none;
                color: $secondary-color;
              }
            }
          }
        }
      }
    }
  }
}

#header-main.smaller .top-bar-top {
  @include breakpoint(large) {
    height: 0;
    transform: translateY(-100px);
    transition: all 0.3s ease-in-out;
  }
}

#header-main .top-bar-top .row {
  @include breakpoint(large) {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
  }
}

#header-main.smaller .top-bar-top .row {
  @include breakpoint(large) {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }
}

.small-icons .columns {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.phone {
  padding-top: 15px;

  .fa-phone {
    display: inline-block !important;
    color: $primary-color;
    margin-right: 5px;
  }
}

//MAIN-MENU
.main-menu {
  text-transform: unset;
  font-size: 20px;
  font-weight: normal !important;
  line-height: 28px;
  background: $primary-color;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  @include breakpoint(large) {
    font-size: 14px;
    background: transparent;

    .menu {
      margin-left: 36px;
    }
  }

  @include breakpoint(1131) {
    font-size: 16px;
  }

  @include breakpoint(1023px down) {
    background: $white;

    >ul {
      width: 100%;
      user-select: none;

      li {
        cursor: pointer;
        width: 100%;

        .submenu-head {
          display: none;
          color: $primary-color;
        }

        &.has-submenu {
          position: relative;

          &::after {
            font-family: 'fontawesome';
            content: '\f105';
            font-size: 30px;
            position: absolute;
            right: 17px;
            color: $primary-color;
            top: 22px;
            transform: translate(0, -50%) rotate(90deg);
            pointer-events: none;
          }

          &.--active {
            &::after {
              transform: rotate(270deg);
              top: -2px;
              right: 19px;
              background: transparent;
            }

            .submenu {
              display: block;

              li {
                a {
                  padding: 10px 20px 10px 20px;
                  color: $primary-color;

                  &:hover {
                    color: $secondary-color;
                  }
                }
              }
            }
          }
        }

        a {
          padding: 15px 10px 15px 0;
          margin-right: 50px;
          color: $primary-color;
        }
      }
    }

    li {
      width: 100%;
      position: relative;
      color: $primary-color;

      a {
        width: max-content;
        color: $primary-color;

        &:hover {
          color: $secondary-color;
          text-decoration: none;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

header.smaller .main-menu {
  display: flex;
  justify-content: flex-end;

  ul {
    li {
      a {
        &:hover {}
      }
    }
  }
}

#main-menu {
  display: flex;
  padding: 0 24px;

  @include breakpoint(1023px down) {
    padding: 0 10px;
  }
}

header.smaller #main-menu {
  height: 67spx;
}

header.smaller #main-menu>.columns {
  height: inherit;
}

.main-menu a:hover,
.main-menu a:active,
.main-menu a:focus,
.main-menu a.active {
  color: $white;
  text-decoration: underline;
}

.main-menu a {
  font-weight: normal !important;

  @media screen and (max-width: 1023px) {
    color: $white;
  }
}

.dropdown.menu>li.is-dropdown-submenu-parent>a::after,
.dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a::after {
  content: none;
  display: none;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a,
.dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a {
  padding-right: 0.7rem;
}

.menu>li>a {
  color: $white;

  @include breakpoint(large) {
    padding: 10px 24px 35px;
  }
}

.smaller {
  .menu>li>a {
    @include breakpoint(large) {
      padding: 25px 24px;
    }
  }
}

.submenu {
  display: none;
}

.submenu>li>a {
  padding: 0.7rem;
}

.main-menu>ul {
  display: inline-block;

  @include breakpoint(large) {
    float: right;
  }
}

.main-menu>ul li {
  z-index: 1;
}

.main-menu li:last-child a {
  padding-right: 0;
}

.submenu,
header.smaller .submenu {
  border: none;

  @include breakpoint(large) {
    padding: 20px;
    @include horizontal-center;
  }
}

header.smaller .submenu {
  @include breakpoint(large) {
    color: $primary-color;
  }
}

header.smaller .submenu a {
  @include breakpoint(large) {
    color: $primary-color;
  }
}

header.smaller .submenu a:hover {
  color: $secondary-color;
}

.submenu-head {
  padding: 20px 20px 0 10px;

  @include breakpoint(medium down) {
    display: none;
  }
}

.menu.vertical>li {
  @include breakpoint(large) {
    display: table-cell;
  }
}

.dropdown.menu.large-horizontal>li.opens-left>.is-dropdown-submenu {
  left: 50%;
}

.is-dropdown-submenu {
  min-width: 65rem;
}

.submenu a {
  font-weight: $global-weight-normal;
  text-transform: none;
}

.submenu {
  &-head {
    color: $primary-color;
  }

  .is-submenu-item {
    a {
      color: $primary-color;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}

.dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu,
.dropdown.menu.vertical>li .is-dropdown-submenu {
  top: 117px;
}

header.smaller .dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu,
header.smaller .dropdown.menu.vertical>li .is-dropdown-submenu {
  top: 67px;
}

.is-dropdown-submenu-parent {
  position: static;
}

.is-dropdown-submenu>li {
  width: auto;
}

.dropdown-pane {
  background: transparent;
  border: none;
}

.menu-icon {
  width: 31px;
  height: 18px;

  &::after {
    width: 31px;
    background: $primary-color !important;
    box-shadow: 0 9px 0 $primary-color, 0 18px 0 $primary-color !important;
  }
}

.columns.newsletter {
  background: $primary-color;
  border-bottom: 5px solid $white;
  padding-right: 30px;
  padding-left: 30px;

  //min-height: 270px;
  /*@include breakpoint(smallium) {
    min-height: 195px;
  }*/
  @include breakpoint(medium only) {
    border-right: 5px solid $white;
  }

  @media screen and (max-height: 750px) {
    padding: 0 15px;
  }

  @media screen and (max-height: 660px) {
    height: 240px;
  }
}

.newsletter .row {
  position: relative;
  height: 100%;
}

.newsletter .columns {
  position: relative;
  height: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:first-of-type {
    padding-right: 40px;
  }

  @include breakpoint(smallium) {
    height: 100%;
  }
}

.newsletter p {
  width: 210px;
  color: $white;
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 0;

  @include breakpoint(smallium) {
    font-size: 25px;
    text-align: right;
  }

  @media screen and (max-height: 750px) {
    width: auto;
  }

  @media screen and (max-height: 580px) {
    font-size: 20px;
  }
}

.newsletter-input {
  width: 100%;
  @include absolute-center;

  button {
    margin-bottom: 0;
  }
}

.newsletter {
  height: 50%;

  @include breakpoint(smallium) {
    height: 37%;
  }

  @include breakpoint(medium) {
    height: calc(50% - 57px);

    @include breakpoint(large) {
      height: 37%;
    }
  }
}

@media screen and (max-height: 760px) {
  .newsletter p {
    font-size: 20px;
  }
}

// DATES
.more-events {
  padding: 20px 30px;

  .columns {
    padding: 0;
  }
}

.date .day,
.date .year {
  color: $white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  font-size: 40px;
  line-height: 40px;

  @include breakpoint(medium only) {
    font-size: 22px;
    line-height: 22px;
  }
}

.date .month {
  color: $white;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;

  @include breakpoint(medium only) {
    font-size: 12px;
  }
}

.date {
  min-height: 70px;
  padding-left: 30px;
  padding-bottom: 5px;
}

.date .columns:first-child {
  background: $primary-color;
  height: 100%;
  padding: 10px;
}

.date .columns:last-child {
  padding: 20px;
}

.date .columns:last-child p {
  margin-bottom: 0;
}

.date:first-child {
  border-bottom: 5px solid $white;
}

//SLIDER
.start-slider .slide {
  height: calc(100vh - 146px);

  @include breakpoint(large down) {
    height: 468px;
  }
}

.teaserSlider {
  @include breakpoint(small) {
    height: auto !important;
  }
}

.content-slider {
  height: 400px;
  min-height: 400px;
  margin-bottom: 80px;

  .content-slider-element {
    height: 400px;
    min-height: 400px;
    padding: 0;

    .content-slider-element,
    .slick-list,
    .slick-track {
      height: 100%;

      @include breakpoint(large) {
        height: inherit;
        min-height: 400px;
      }
    }

    .slick-dots {
      li {
        width: 15px;
        height: 15px;

        button {
          background: $secondary-color;
          width: 15px;
          height: 15px;

          &:before {
            display: none;
          }
        }

        &.slick-active,
        &:hover {
          button {
            background: $secondary-color;
          }
        }
      }
    }
  }
}

.slide {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .slide-text {
    .button {
      background: $primary-color;
      color: $white;
      text-transform: unset;
      font-size: 24px;
      line-height: 33px;
      padding: 12px 18px;
      width: unset;

      @include breakpoint(1023px down) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.slide img {
  width: 100%;
}

.slide-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 860px;
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.9);
  padding: 58px;

  @include breakpoint(large down) {
    padding: 10px;
  }

  .fi {
    color: $white;
    margin-right: 5px;
  }
}

.slide-text p {
  margin-bottom: 0;
  text-align: center;
}

.slide-head {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  line-height: 41px;
  color: $primary-color;

  @include breakpoint(medium) {
    font-size: 60px;
    line-height: 82px;
  }
}

.slide-sub-head {
  font-size: 20px;
  font-weight: 300;
  line-height: 37px;
  text-align: center;
  margin-bottom: 20px !important;
  color: $primary-color;

  @include breakpoint(medium) {
    font-size: 40px;
    line-height: 54px;
    margin-bottom: 40px !important;
  }
}

.big-picture {
  margin-bottom: 80px;
  position: relative;

  .big-picture-caption {
    z-index: 100;
  }

  figure {
    overflow: hidden;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  img {
    min-width: 100%;

    @include breakpoint(small only) {
      min-height: 200px;
      max-height: 200px;
      max-width: inherit;
      object-fit: cover;
    }
  }

  &.--imageLeft {
    img {
      object-fit: cover;
      object-position: left;
    }
  }

  &.--imageCenter {
    img {
      object-fit: cover;
      object-position: center;
    }
  }

  &.--imageRight {
    img {
      object-fit: cover;
      object-position: right;
    }
  }
}

.--bgRight {
  background-position: right center;
}

.--bgLeft {
  background-position: left center;
}

.big-picture-caption {
  max-width: 50%;
  position: absolute;
  left: 10%;
  bottom: 0px;
  color: $white;

  @include breakpoint(smallium) {
    bottom: 10px;
  }

  @include breakpoint(medium) {
    bottom: 50px;
  }

  h2 {
    font-size: 20px;
    color: $white;
    font-weight: 400;

    @include breakpoint(medium) {
      font-size: 36px;
    }

    @include breakpoint(large) {
      font-size: 52px;
    }
  }

  h3 {
    font-size: 18px;
    color: $white;

    @include breakpoint(large) {
      font-size: 30px;
    }
  }

  a {
    color: $white;
  }
}

.newsletter-fullwidth {
  height: 440px;
  margin-bottom: 10px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .row {
    margin-bottom: 0;
  }

  .newsletter-signup-wrapper {
    h2 {
      color: $white;
      font-size: 36px;
      line-height: 36px;
      margin: 0 0 80px;
    }

    p {
      max-width: 80%;
      font-size: 26px;
      color: $white;
    }

    .column,
    .columns {
      height: auto;
      min-height: inherit;
    }

    .newsletter-input {
      position: relative;
      top: inherit;
      left: inherit;
      transform: inherit;
    }
  }
}

.bottom-line {
  border-bottom: 5px solid $light-gray;
}

//FOOTER
#footer-main {
  a {
    color: $black;
    font-weight: $global-weight-normal;
  }

  section {
    box-shadow: 0 -5px 0 0 $white, 0 -10px 0 0 $primary-color;
    background: repeating-linear-gradient(-31.5deg,
        $primary-color,
        $primary-color 200px,
        #0080dd 200px,
        #0080dd 400px,
        $primary-color 400px,
        $primary-color 450px,
        #025ea0 450px,
        #025ea0 900px);
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 20px;

    a {
      color: $white;

      &:hover {
        color: $white;
        text-decoration: underline;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }
  }

  ul {
    margin-left: 0;
  }

  li {
    list-style-type: none;
  }
}

/* Content Col2 */

.col2-wrapper {
  .content-wrapper-left {
    .row {
      margin-bottom: 40px;

      .row {
        margin-bottom: 0;
      }
    }
  }

  .content-wrapper-right {
    h2 {
      font-size: 1.3rem;
    }

    h3 {
      font-size: 1rem;
    }

    .row {
      margin-bottom: 0;
    }

    .widget {
      display: flex;
      margin-bottom: 40px;
    }

    .shop-button-widget {
      .shop {
        border: 0 none;

        &:after {
          content: '\f07a';
          font-family: 'Fontawesome';
          font-size: 60px;
          font-weight: normal;
          position: absolute;
          right: 30px;
          bottom: 0px;

          @include breakpoint(smallium) {
            font-size: 100px;
          }

          @include breakpoint(medium) {
            font-size: 80px;
          }

          @include breakpoint(large) {
            font-size: 80px;
          }
        }
      }
    }

    .kis-button-widget {
      .kis {
        border: 0 none;

        &:after {
          content: '\f05a';
          font-family: 'Fontawesome';
          font-size: 60px;
          position: absolute;
          right: 30px;
          bottom: 0px;
          font-weight: normal;

          @include breakpoint(smallium) {
            font-size: 100px;
          }

          @include breakpoint(medium) {
            font-size: 80px;
          }

          @include breakpoint(large) {
            font-size: 80px;
          }
        }
      }
    }

    .news-widget {
      padding-top: 20px;
      background: #eaeaea;

      .news {
        margin: 0 0 15px 0;
        padding: 0 0 12px 0;

        header {
          margin-bottom: 5px;
        }

        .date {
          border: 0 none;
          padding: 0;
          margin: 0;
          font-weight: 400;
          font-size: 0.8rem;
        }

        .read-more {
          font-size: 0.8rem;
        }
      }
    }

    .termine-widget {
      padding-top: 20px;
      background: #eaeaea;

      .row.date {
        margin-bottom: 5px;
      }
    }

    .newsletter-widget {
      .newsletter p {
        position: relative;
        top: inherit;
        left: inherit;
        transform: inherit;
        font-size: 1.6rem;
      }

      .column,
      .columns {
        height: auto;
        min-height: inherit;
      }

      .newsletter-input {
        position: relative;
        top: inherit;
        left: inherit;
        transform: inherit;
      }
    }
  }
}

.contact-widget {
  padding: 20px 0;
  background: #eaeaea;
  min-height: 370px;
  margin-bottom: 20px;

  header {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .row {
    margin-bottom: 0;
  }

  .contact-box {
    font-weight: 400;
    font-size: 0.85rem;

    .image {
      margin-bottom: 10px;
      float: left;
      width: 100%;
    }

    .infos {
      margin-bottom: 10px;
    }

    a {
      font-size: 0.85rem;

      &:hover {
        color: $secondary-color;
      }
    }

    .label {
      font-size: 1rem;
      font-weight: 600;
      width: 20px;
      display: inline-block;
      text-align: center;
    }

    .person {
      margin-bottom: 10px;
      color: $primary-color;

      span {
        display: block;
      }

      .name {
        font-weight: bold;
        font-size: 1rem;
        color: $primary-color;
      }
    }

    .quote {
      font-style: italic;
    }

    .contact {
      line-height: 1.35rem;

      .fax,
      .mail {
        .label {
          font-size: 0.8rem;
        }
      }

      .mobile {
        .label {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.content-wrapper-right {
  .contact-widget {
    .contact-box {
      .image {
        width: 100%;
        padding-right: 0;
        margin-right: 10px;
        margin-bottom: 10px;
        float: left;

        img {
          width: 100%;
        }
      }
    }
  }
}

/* News-List */

.news-list {
  .row {
    &.news {
      margin: 40px 0 0 0;
      background: $light-gray;
      position: relative;
    }

    .news-list-image {
      padding: 0;
      width: 25%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      position: absolute;
    }

    .news-list-content {
      padding: 20px;
      background: $light-gray;
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .date {
    &:first-child {
      border: 0 none;
    }

    border: 0 none;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 0.8rem;
  }
}

/* News-Detail */

.news-detail {
  header {
    margin-bottom: 20px;

    .date {
      &:first-child {
        border: 0 none;
      }

      border: 0 none;
      padding: 0;
      margin: 0;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }
}

/* Accordion */

.accordion {
  border: 0 none;

  .accordion-title {
    padding: 1.5rem 40px 1.5rem 1rem;
    border: 0 none;
    color: #003782;
    border-bottom: 2px solid rgb(231, 231, 231);
    font-weight: 500;
    font-size: 25px;

    &:before {
      content: '';
      position: absolute;
      border-left: 2px solid #003782;
      border-bottom: 2px solid #003782;
      width: 10px;
      height: 10px;
      transform: rotate(-45deg);
      top: 37px;
      transition: all 0.35s;
    }
  }

  .accordion-content {
    padding: 1.5rem 1rem;
    border: 0 none;
  }

  .accordion-item.is-active {
    .accordion-title {
      background: white !important;

      &::before {
        transform: rotate(135deg);
        transition: all 0.35s;
        top: 42px;
      }

      &:hover {
        background: rgb(231, 231, 231) !important;
      }
    }
  }
}

/* Pagination */

.pagination {
  margin-top: 45px;

  ul {
    float: none;

    li {
      display: inline-block;
      margin-right: 5px;

      &.current {
        padding: 9px 12px;
        line-height: 16px;
        border: 3px solid $primary-color;
        background: $primary-color;
        color: #fff;
        font-weight: bold;
      }

      &.no-border {
        padding: 10px 7px;
        line-height: 16px;
        color: #444;
      }

      a {
        padding: 8px 12px;
        line-height: 16px;
        border: 3px solid $light-gray;
        background: $light-gray;
        color: $dark-gray;

        &:hover {
          background: $white;
          text-decoration: none;
        }
      }
    }
  }
}

/* Event-List */

.events-list {
  .row {
    &.event {
      margin-top: 40px;
    }

    .event-list-content {
      background: $light-gray;
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .date {
    border: 0 none;
    padding: 0;
    margin: 0;
    font-weight: 400;

    &:first-child {
      border: 0 none;
    }

    .date-box-wrapper {
      display: flex;
      justify-content: center;

      .date-box {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .day,
        .year {
          @media screen and (max-width: 639px) {
            font-size: 22px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

/* Event-Detail */

.event-detail {
  header {
    margin-bottom: 20px;

    .date {
      &:first-child {
        border: 0 none;
      }

      border: 0 none;
      padding: 0;
      margin: 0;
      font-weight: 400;
    }
  }

  .label {
    font-size: inherit;
    font-weight: 600;
    width: 20px;
    display: inline-block;
    text-align: center;
  }

  .date-box-wrapper {
    @media screen and (max-width: 639px) {
      height: 80px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .date-box {
      display: block;

      .day,
      .year {
        @media screen and (max-width: 639px) {
          font-size: 22px;
        }
      }
    }
  }

  .date {
    @media screen and (max-width: 639px) {
      padding: inherit;
    }

    .columns:last-child {
      padding: 10px 0;
    }
  }

  .info-box-wrapper {
    position: relative;
    line-height: 1.4rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 639px) {
      height: auto !important;
      display: block;
      padding: 0 10px !important;
    }

    .info-box {
      height: 100%;
      border-left: 5px solid $light-gray;
      padding-left: 10px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 639px) {
        border: 0 none;
        padding-left: 0;
      }

      .time-wrapper {
        position: relative;
        top: -22px;

        @media screen and (max-width: 639px) {
          top: inherit;
        }
      }
    }
  }

  .places-box-wrapper {
    position: relative;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    padding: 0 10px;

    @media screen and (max-width: 639px) {
      height: auto !important;
      display: block;
    }

    .place-box {
      padding: 10px 0;

      @media screen and (max-width: 639px) {
        padding: 0 0 10px 0;
      }

      .company {
        font-weight: bold;
        margin-bottom: 10px;
        color: $primary-color;
      }

      .address {
        display: block;
        margin-left: 25px;
      }
    }
  }

  .event-registration {
    padding: 20px 0 0 0;
    margin: 20px 0 !important;
    /* todo: only DEV */
  }
}

.date-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Searchresult */

.searchresult-wrapper {
  position: relative;

  .searchbar {
    margin-bottom: 10px;

    .row,
    section {
      margin-top: 0;
      margin-bottom: 0;
    }

    .columns {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
    }

    .search-info {
      display: flex;
      justify-content: flex-end;

      h1,
      span {
        font: {
          size: 0.8rem;
          weight: 400;
        }

        line-height: inherit;
        color: $dark-gray;
        margin: 0;
        display: inline-block;
      }

      .result-count {
        font-weight: 400;
      }
    }
  }

  .searchresults {
    .row {
      margin-bottom: 0;
    }

    .searchresult-item {
      padding: 15px;
      margin-bottom: 40px;
      position: relative;
      background: $light-gray;

      &:nth-child(2n) {
        border-left: 4px solid $light-gray;
      }

      &:last-of-type {
        float: left;
      }

      .search-match {
        background-color: $light-blue-trans;
      }
    }
  }
}

/* PDF-Katalog */

.pdf-katalog {
  .pdf-katalog-featured {
    border: 3px solid $white;
    outline: none;

    .info {
      &:before {
        display: none;
      }
    }

    .slick-slide {
      display: flex;
      align-items: center;
      outline: none;
    }

    .thumb {
      margin-right: 20px;

      figure {
        height: 100%;

        img {
          max-height: 315px;
          width: auto;
        }
      }
    }

    .info {
      header {
        margin: 25px 0;
      }
    }
  }

  .pdf-katalog-list {
    .info {
      &:before {
        display: none;
      }
    }

    .slider-wrap,
    .slider-element,
    .slick-list,
    .slick-track {
      height: inherit !important;
      width: inherit !important;

      .slide {
        position: inherit;
      }

      .slide img {
        width: inherit;
      }
    }

    .slide {
      width: 50% !important;
      opacity: 1 !important;
      left: inherit !important;
      top: inherit !important;
      float: left !important;

      &:focus {
        outline: none;
      }

      @media screen and (max-width: 639px) {
        width: 100% !important;
      }
    }

    .katalog {
      background: $light-gray;
      padding-top: 7px;
      padding-bottom: 5px;
      border: 3px solid $white;
      height: 68px;
      position: relative;

      .pdf-icon {
        position: absolute;
        left: 5px;
        top: 7px;

        .icon {
          display: inline-block;
          width: 2rem;
          height: 2rem;
          stroke-width: 0;
          stroke: $dark-gray;
          fill: $dark-gray;
          position: relative;
          top: inherit;
          left: inherit;
          margin: 0;
        }
      }

      .info {
        position: absolute;
        left: 52px;
        top: 10px;

        h4,
        p {
          margin: 0;
        }
      }

      .download-icon {
        display: flex;
        align-self: flex-end;
        margin-right: 10px;
        position: absolute;
        right: 0;

        a {
          font-size: 2rem;

          &:hover {
            i {
              color: $secondary-color;
            }
          }
        }
      }

      &:hover {
        background: $dark-gray;
        cursor: pointer;

        .icon {
          stroke: $white;
          fill: $white;
        }

        h4,
        p,
        a,
        i {
          color: $white;
        }

        a {
          &:hover {
            i {
              color: $light-gray;
            }
          }
        }
      }

      &.slick-current {
        background: $primary-color;

        .icon {
          stroke: $white;
          fill: $white;
        }

        h4,
        p,
        a,
        i {
          color: $white;
        }

        a {
          &:hover {
            i {
              color: $light-gray;
            }
          }
        }
      }
    }
  }
}

.row-logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  /* Default for 1600x900 videos 16:9 ratio*/
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ce-badge {
  position: fixed;
  z-index: 9999;
  top: 70px;
  left: 50px;
  background: $primary-color;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-10deg);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 1600px) {
    top: 180px;
  }

  @media screen and (max-width: 1024px) {
    top: 80px;
    left: inherit;
    right: 50px;
  }

  @media screen and (max-width: 639px) {
    display: none;
  }

  a {
    padding: 10px;
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background: $primary-color;
    border: 3px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font: {
      size: 15px;
      weight: 600;
    }

    line-height: 21px;
    text-align: center;
    color: $white;

    .highlight {
      font-size: 22px;
      line-height: 25px;
    }

    .badge-content {
      width: 100%;
      max-width: 100%;
    }
  }
}

.content-wrapper-right .columns>header,
.event-registration>header,
.news-widget h2,
.news-widget h3,
.termine-widget h2,
.termine-widget h3 {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.start-slider {
  margin-bottom: 120px;
  position: relative;

  @include breakpoint(medium down) {
    margin-bottom: 80px;
    // height: 486px;
  }

  @include breakpoint(small down) {
    display: flex;
    flex-direction: column;
  }

  .slick-prev,
  .slick-next {
    bottom: inherit;
    left: inherit;
    top: 50%;
    z-index: 130;
    font-weight: 300;
    background: $primary-color;
    width: 50px;
    height: 50px;
    opacity: 0.9;
    border-radius: 4px;

    &:after,
    &:before {
      line-height: inherit;
      position: relative;
      color: white;
      font-size: 50px;
      top: 7px;

      @include breakpoint(medium down) {
        font-size: 36px;
      }
    }

    @include breakpoint(1023px down) {
      display: none !important;
    }
  }

  .slick-prev {
    left: 24px;

    &::before {
      left: -2px;
    }
  }

  .slick-next {
    right: 24px;

    &::before {
      right: -2px;
    }
  }
}

.slider-element {
  position: relative;

  .button {
    width: 160px;
    display: block;
    background: $white;
    color: $primary-color;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);

    &:after {
      display: none;
    }
  }
}

li:hover a div i.fa.fa-facebook {
  color: white;
}

// BTN BIG

.btn-big {
  display: block;
  position: relative;
  font-size: 30px;
  font-weight: 400;
  padding-left: 5px;
  text-transform: uppercase;

  &:after {
    content: '\f101';
    display: block;
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 30px;
    font-family: 'Fontawesome';
    font-weight: normal;
    color: $dark-gray;
  }

  &.button-blf40 {
    color: $secondary-color;
    border-bottom: 4px solid $secondary-color;
    padding-left: 50px;

    &:before {
      content: '\f07a';
      display: block;
      position: absolute;
      top: 0;
      left: 10px;
      font-size: 30px;
      font-family: 'Fontawesome';
      font-weight: normal;
    }
  }

  &.button-shop {
    color: $primary-color;
    border-bottom: 4px solid $primary-color;
  }

  &:hover {
    color: $dark-gray;
  }
}

#exampleModal1 {
  top: 141px !important;
}

.newsletter .newsletter-input {
  width: 100%;
  position: static;
  top: 50%;
  left: 50%;
  transform: none;
}

.slide {
  video {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    min-width: 100vw !important;
    min-height: 100% !important;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);

    .slide-text {
      display: block;
      z-index: 1;
    }
  }
}

.news-detail a {
  hyphens: auto;
}

.wrapper {
  position: relative;

  .mapWrapper {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .switch {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;

    .switch-list,
    .switch-map {
      background: rgba(0, 49, 119, 0.5);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.35s all;
      cursor: pointer;

      i {
        color: white;
        font-size: 27px;
      }

      &:hover,
      &.active {
        background: $primary-color;
      }
    }

    .switch-list {
      margin-right: 20px;
    }
  }
}

#blf-table {
  &.none {
    display: none;
  }

  table {
    thead {
      background: white;
      border: 0 none;
      border-bottom: 2px solid $primary-color;
      padding-bottom: 20px;

      tr {
        th {
          color: $primary-color;
          font-weight: normal;
          position: relative;
          font-size: 25px;

          span {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              height: 30px;
              width: 2px;
              background: $primary-color;
              left: -20px;
              bottom: 0;
              top: 0;
            }
          }
        }
      }
    }

    tbody {
      border: 0 none;

      tr {
        td {
          margin: 10px 0;
          padding: 20px;
          background: rgba(224, 228, 228, 1);

          img {
            width: auto;
            max-height: 100px;
          }
        }
      }
    }
  }
}

.tableHead,
.tableBody {
  display: flex;

  .tableRow {
    background: rgba(224, 228, 228, 1);
    display: flex;

    >div {
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include breakpoint(medium down) {
        padding: 10px;
      }

      &:nth-child(1) {
        width: calc((100% / 12) * 2);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:nth-child(2) {
        width: calc((100% / 12) * 3);

        @include breakpoint(medium down) {
          width: calc((100% / 12) * 5);
        }
      }

      &:nth-child(3) {
        width: calc((100% / 12) * 3);
      }

      &:nth-child(4) {
        width: calc((100% / 12) * 2);

        @include breakpoint(medium down) {
          display: none;
        }
      }

      &:nth-child(5) {
        width: calc((100% / 12) * 2);
        display: flex;

        span {
          width: 100%;
        }

        .mobile {
          display: none;
        }

        @include breakpoint(medium down) {
          justify-content: flex-end;

          .mobile-none {
            display: none;
          }

          .mobile {
            display: block !important;

            i {
              color: $primary-color;
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}

.tableHead {
  border-bottom: 2px solid $primary-color;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: normal;
  color: $primary-color;

  @include breakpoint(medium down) {
    font-size: 18px;
    line-height: 1;
  }

  .tableRow {
    background: white;
    width: 100%;

    >div {
      &:first-child {
        span {
          &::after {
            display: none;
          }
        }
      }

      span {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          height: 100%;
          width: 2px;
          background: $primary-color;
          left: -20px;

          @include breakpoint(medium down) {
            display: none;
          }
        }
      }
    }
  }
}

.tableBody {
  display: flex;
  flex-direction: column;

  .tableRow {
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
    min-height: 120px;

    span {
      hyphens: auto;
      color: $primary-color;
    }

    img {
      max-height: 80px;
      width: auto;
    }
  }
}

.blf-map {
  &.none {
    display: none;
  }

  .map {
    width: 100%;
    height: 600px;

    @include breakpoint(medium down) {
      height: 350px;
      max-width: 100% !important;
    }
  }
}

/**
 * The zoomslider in the second map shall be placed between the zoom-in and
 * zoom-out buttons.
 */
#map .ol-zoom .ol-zoom-out {
  margin-top: 204px;
}

#map .ol-zoomslider {
  background-color: transparent;
  top: 2.3em;
}

#map .ol-touch .ol-zoom .ol-zoom-out {
  margin-top: 212px;
}

#map .ol-touch .ol-zoomslider {
  top: 2.75em;
}

#map .ol-zoom-in.ol-has-tooltip:hover [role='tooltip'],
#map .ol-zoom-in.ol-has-tooltip:focus [role='tooltip'] {
  top: 3px;
}

#map .ol-zoom-out.ol-has-tooltip:hover [role='tooltip'],
#map .ol-zoom-out.ol-has-tooltip:focus [role='tooltip'] {
  top: 232px;
}

.ol-zoom,
.ol-zoomslider {
  top: unset !important;
  left: unset !important;
  right: 16px !important;
  bottom: 0 !important;
}

.ol-zoom {
  background: none !important;

  @include breakpoint(medium down) {
    width: 35px !important;
  }

  button {
    color: $primary-color !important;
    background: transparent !important;
    font-size: 25px !important;
  }
}

.ol-zoomslider {
  bottom: 1.9em !important;
  background: $primary-color !important;
  border-radius: 25px !important;
  right: 20px !important;
  width: 31px;
  bottom: 2.35em !important;

  @include breakpoint(medium down) {
    width: 20px;
  }

  button {
    background: rgba(99, 188, 101, 0.7) !important;
    width: 62px !important;
    border-radius: 25px !important;
    left: -18px;

    @include breakpoint(medium down) {
      width: 40px !important;
      left: -13px;
    }
  }
}

.popup {
  width: max-content;
  min-width: 200px;
  height: max-content;
  padding: 10px;
  background: $primary-color;
  z-index: 9000;
  position: relative;
  display: none;
  margin-bottom: -3px;

  &-active {
    display: flex !important;
    justify-content: flex-start;
  }

  &.-neu {
    margin-bottom: 9px;
  }

  img {
    height: 80px;
    width: auto;
    max-width: 50px;
    object-fit: contain;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -115px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 116px 0 0;
    border-color: #003782 transparent transparent transparent;
    transition: border-color 0.25s ease-out;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -30px;
    right: calc(50% - 28px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 24px 1px 24px;
    border-color: #003782 transparent transparent transparent;
    transition: border-color 0.25s ease-out;
  }

  .pop-up-content {
    color: white;
    margin-left: 10px;

    ul {
      padding: 0;
      margin: 0 0 10px;
      white-space: initial;

      li {
        list-style: none;
        color: white;
        font-size: 12px;
        white-space: initials;
        line-height: 1;
      }
    }

    a {
      margin-top: 10px;
      color: white;
      padding: 5px;
      border: 1px solid white;
      border-right: 0 none;
      font-weight: normal;
      font-size: 12px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        right: -42px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 29px 43px 0 0;
        border-color: white transparent transparent transparent;
        transition: border-color 0.25s ease-out;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0px;
        right: -38px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 27.5px 40px 0 0;
        border-color: #003782 transparent transparent transparent;
        transition: border-color 0.25s ease-out;
      }
    }
  }
}

// remove icon on video player
.bn-youtube-player {
  .play {
    &::before {
      display: none;
    }
  }
}

// weber

.metaSearch {
  position: relative;
  margin-right: 20px;
  padding: 0;
  margin: 0;

  form {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    transition: all 0.35s;

    input {
      border: 0;
      width: 0;
      transition: all 0.35s;
      font-size: 14px;
      color: $white;
      padding: 0;
      margin: 0;
      height: 22px;
      box-shadow: none;
      background: transparent;

      &::placeholder {
        color: $white;
        transition: all 0.35s;
        opacity: 0;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.--active {
      border-bottom: 1px solid $white;
      transition: all 0.35s;

      @include breakpoint(1023px down) {
        border-bottom: 1px solid $primary-color;
      }

      button {
        transition: all 0.35s;
      }

      input {
        margin-left: 7px;
        width: 100%;
        max-width: 174px;
        transition: all 0.35s;
        background: transparent;

        @include breakpoint(1023px down) {
          color: $black;
        }

        &::placeholder {
          transition: all 0.35s;
          opacity: 1;
          color: $black;
        }
      }
    }

    .metaSearch__button {
      border: none;
      padding: 0;
      cursor: pointer;
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
      mask: url('../img/svg/IconLupe.svg') no-repeat center;
      background: white;

      @include breakpoint(1023px down) {
        background: $primary-color;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.show-for-large {
  &~div {
    width: 100%;
  }
}

.small-icons {
  height: 67px;

  .metaSearch {
    height: 100%;
    margin: 5px;
    display: none;

    .metaSearch__button {
      mask-size: 28px;
    }
  }
}

.smaller {
  .small-icons {
    .metaSearch {
      display: block;
      width: 230px;

      input {
        &::placeholder {
          color: $white;
        }
      }
    }
  }

  .show-for-large {
    width: 300px;
  }
}

.top-bar-top {
  transition: all 0.35s;

  @include breakpoint(1023px down) {
    .metaSearch {
      display: none;
    }
  }
}

#header-main {
  &.smaller {
    .small-icons {
      .logo-smaller {
        // transform: translateX(-100%);
      }
    }
  }

  &.open {
    .metaSearch {
      display: block;
      margin: 0 auto;

      .metaSearch__button {
        mask-size: 24px;
        height: 30px;
        width: 30px;
      }
    }

    .top-bar-top {
      position: relative;
      padding: 0 24px;

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 24px);
        left: 12px;
        height: 1px;
        background: $primary-color;
        bottom: 0;
      }

      .metaSearch {
        display: none;
      }

      &+.main-menu {
        padding: 0 24px;
        border-bottom: 3px solid $primary-color;
      }
    }
  }
}

#main-menu {
  .top-bar-top {
    .metaSearch {
      input {
        &::placeholder {
          color: white;
        }
      }
    }
  }
}

.tab-list {
  display: flex;
  border: 1px solid $medium-gray;
  margin: 0 auto;
  padding: 5px;
  margin: 50px auto 55px;
  width: max-content;

  li {
    list-style: none;
    height: 100%;

    +li {
      margin-left: 10px;
    }

    button {
      padding: 0 10px;
      color: $medium-gray;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      font-weight: 400;
      display: block;
      height: 100%;

      &.active {
        background: $primary-color;
        display: block;
        color: $white;
      }

      &:hover {
        color: $white;
        background-color: $medium-gray;
      }
    }
  }
}

.blog-item {
  margin-bottom: 20px;
  display: block;
  transition: all .35s linear;

  &:hover {
    box-shadow: 0px 4px 20px 0px rgba(2, 22, 74, 0.35);
  }

  .imgWrapper {
    height: 207px;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;

    +p {
      margin-bottom: 0;
    }
  }

  .content {
    padding: 15px 15px 20px;
    background: $primary-color;
    color: $white;

    >div {
      &:first-of-type {
        display: flex;
        justify-content: space-between;
      }

      span {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
      }

      .tag {
        background-color: $white;
        color: $primary-color;
        padding: 0 10px;
        text-transform: uppercase;
      }

      +div {
        margin-top: 20px;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}

.contact-item {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  height: 170px;

  @include breakpoint(medium down) {
    flex-direction: column;
  }

  .imgWrapper {
    aspect-ratio: 1 / 1;
    display: block;

    @include breakpoint(medium down) {
      height: 240px;
      aspect-ratio: unset;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    padding: 30px;
    background-color: $primary-color;
    color: $white;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;

    >div {
      margin-top: 16px;

      >span {
        color: $white;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;

        &:first-of-type {
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }

    @include breakpoint(medium down) {
      max-width: unset;
    }

    span {
      display: block;

      &:first-of-type {
        font-size: 24px;
        line-height: 28px;
        font-weight: 300;

        +span {
          margin-top: 5px;
          display: block;
        }
      }

      &:last-of-type {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;

        +a {
          margin-top: 20px;
        }
      }
    }

    a {
      color: $white;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.m-b-0 {
  margin-bottom: 0;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-50 {
  margin-top: 50px;
}

.p-t-50 {
  padding-top: 50px
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-50 {
  padding-top: 50px;
  padding-bottom: 50px;

  @include breakpoint(medium down) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.columns {
  &.-center {
    display: flex;
    justify-content: center;
  }

  &.-element-center {
    margin-left: auto;
    margin-right: auto;
    float: unset !important;
  }
}

.arrow-back {
  color: $medium-gray;
  position: relative;
  padding-left: 16px;
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  &:hover {
    &::after {
      background-color: $primary-color;
    }
  }

  &::after {
    content: '';
    position: absolute;
    mask-image: url('../img/svg/sliderArr.svg');
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    height: 24px;
    width: 9px;
    background: $medium-gray;
    bottom: 0;
    left: 0;
  }
}

.imgWrapper {
  display: block;

  img {
    display: block;
    width: 100%;
  }
}