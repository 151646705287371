.neos-backend label {
  color: #ffffff;
}

.neos-backend .empty-slider{
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.neos-backend .dashboard .slide {
  min-height: 80vh;
}

.neos-backend .dashboard-last .scrolldown{
  min-height: 175px;
}

#header-main.smaller .is-submenu-item a{
  color: #003782;
}

a.pdf:before{
  content: '' !important;
  padding-right: 0 !important;
}

.contact .mail {
  min-height: 43px;
}



.ms-options-wrap,
.ms-options-wrap * {
  box-sizing: border-box;
}

.ms-options-wrap > button:focus,
.ms-options-wrap > button {
  position: relative;
  width: 100%;
  text-align: left;
  border: 1px solid #aaa;
  background-color: #fff;
  padding: 5px 20px 5px 5px;
  margin-top: 1px;
  font-size: 13px;
  color: #aaa;
  outline: none;
  white-space: nowrap;
}

.ms-options-wrap > button:after {
  content: ' ';
  height: 0;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 0;
  border: 6px solid rgba(0, 0, 0, 0);
  border-top-color: #999;
  margin-top: -3px;
}

.ms-options-wrap > .ms-options {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 1px;
  margin-bottom: 20px;
  background: white;
  z-index: 2000;
  border: 1px solid #aaa;
}

.ms-options-wrap > .ms-options > .ms-search input {
  width: 100%;
  padding: 4px 5px;
  border: none;
  border-bottom: 1px groove;
  outline: none;
}

.ms-options-wrap > .ms-options .ms-selectall {
  display: inline-block;
  font-size: .9em;
  text-transform: lowercase;
  text-decoration: none;
}
.ms-options-wrap > .ms-options .ms-selectall:hover {
  text-decoration: underline;
}

.ms-options-wrap > .ms-options > .ms-selectall.global {
  margin: 4px 5px;
}

.ms-options-wrap > .ms-options > ul,
.ms-options-wrap > .ms-options > ul > li.optgroup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ms-options-wrap > .ms-options > ul > li.optgroup {
  padding: 5px;
}
.ms-options-wrap > .ms-options > ul > li.optgroup + li.optgroup {
  border-top: 1px solid #aaa;
}

.ms-options-wrap > .ms-options > ul > li.optgroup .label {
  display: block;
  padding: 5px 0 0 0;
  font-weight: bold;
}

.ms-options-wrap > .ms-options > ul label {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 4px;
  margin: 1px 0;
  border: 1px dotted transparent;
}

.ms-options-wrap > .ms-options > ul label.focused,
.ms-options-wrap > .ms-options > ul label:hover {
  background-color: #efefef;
  border-color: #999;
}

.ms-options-wrap > .ms-options > ul li.selected label {
  background-color: #efefef;
  border-color: transparent;
}

.ms-options-wrap > .ms-options > ul input[type="checkbox"] {
  margin: 0 5px 0 0;
  position: absolute;
  left: 4px;
  top: 7px;
}

.ms-options-wrap > .ms-options.hide-checkbox > ul input[type="checkbox"] {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

#subscribeForm .error {
  background: rgba(255, 159, 174, 0.43);
}


@media screen and (min-width: 64em) {
  .pdf-katalog .slider-wrap, .pdf-katalog .slider-element, .pdf-katalog .content-slider-element , .pdf-katalog .slick-list, .pdf-katalog .slick-track
  {
    height: inherit;
    min-height: 0;
  }
}