$duration: 40s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ce-ticker {
  position: fixed;
  z-index: 100;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 4rem;
  background-color: rgba(#000, 0.9);
  padding-left: 100%;  // offset items to begin

  .label {
    position: absolute;
    bottom: 0;
    left: 0;
    background: $primary-color;
    height: 4rem;
    z-index: 999;
    font: {
      size: 36px;
    }
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    color: $white;
  }

  .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap; // display items in a line
    padding-right: 100%; // ensure items go before animations repeat (taken from parent due to inline-block)
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: $duration;

    &-item {
      display: inline-block;
      font-size: 2rem;
      color: $white;

      &:after {
        content: '|';
        display: inline-block;
        font-size: 2rem;
        color: $primary-color;
        padding: 0 1rem;
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }
}
